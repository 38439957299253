import React, { useEffect } from "react";
import { DateRangePicker } from "mui-daterange-picker";
import {
	TextField,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	Box,
} from "@mui/material";

const DateRangeComponent = ({ setDateCallback }) => {
	const [open, setOpen] = React.useState(false);
	const [dateRange, setDateRange] = React.useState(null);
	const [disable, setDisable] = React.useState(true);
	const toggle = () => setOpen(!open);

	const handleApply = (range) => {
		setDateRange(range);
		setOpen(false); // Close the DateRangePicker after selecting
	};

	const handleInputClick = () => {
		setOpen(true); // Open the DateRangePicker when input is clicked
	};

	const handleClose = () => {
		setOpen(false); // Close the DateRangePicker when cancel is clicked
	};

	useEffect(() => {
		setDateCallback(dateRange);
		//eslint-disable-next-line
	}, [dateRange]);
	return (
		<Box>
			<TextField
				sx={{ minWidth: "200px" }}
				disabled={!disable}
				variant="outlined"
				size="small"
				label="Select Date Range"
				value={
					dateRange
						? `${dateRange.startDate.toLocaleDateString()} - ${dateRange.endDate.toLocaleDateString()}`
						: ""
				}
				onClick={handleInputClick}
				InputProps={{
					readOnly: true,
				}}
			/>
			<Dialog open={open} onClose={handleClose} maxWidth>
				<DateRangePicker
					open={open}
					toggle={toggle}
					onChange={(range) => handleApply(range)}
				/>
			</Dialog>
		</Box>
	);
};

export default DateRangeComponent;
