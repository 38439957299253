import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Fade } from "@mui/material";
import Backdrop from "@mui/material/Backdrop"; // Import Backdrop

export default function AlertDialog({
	open,
	onClose,
	title,
	description,
	onAgree,
	onDisagree,
	agreeText = "Yes, Disable",
	disagreeText = "Cancel",
}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			closeAfterTransition
			slots={{ backdrop: Backdrop }}
			slotProps={{ backdrop: { TransitionComponent: Fade } }}
			sx={{
				"& .MuiDialog-paper": {
					padding: "20px",
					borderRadius: "12px",
					maxWidth: "500px",
					width: "90%",
					transition: "transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out",
					"&:hover": {
						transform: "scale(1.02)",
						boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.2)",
					},
				},
			}}
		>
			<DialogTitle
				id="alert-dialog-title"
				sx={{
					display: "flex",
					alignItems: "center",
					color: "text.primary",
					fontWeight: "bold",
					fontSize: "18px",
					paddingBottom: "10px",
					transition: "color 0.3s ease-in-out",
				}}
			>
				<ErrorOutlineIcon color="error" sx={{ mr: 1, fontSize: 28 }} />
				{title}
			</DialogTitle>
			<DialogContent>
				<DialogContentText
					id="alert-dialog-description"
					sx={{ fontSize: "16px", color: "text.secondary" }}
				>
					{description}
				</DialogContentText>
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: "flex-end",
					gap: "10px",
					paddingTop: "10px",
				}}
			>
				<Button
					sx={{
						textTransform: "none",
						color: "text.primary",
						backgroundColor: "background.default",
						transition:
							"background-color 0.3s ease-in-out, transform 0.3s ease-in-out",
						"&:hover": {
							backgroundColor: "background.paper",
							transform: "scale(1.02)",
						},
					}}
					onClick={onDisagree}
				>
					{disagreeText}
				</Button>
				<Button
					sx={{
						textTransform: "none",
						backgroundColor: "error.main",
						color: "common.white",
						transition:
							"background-color 0.3s ease-in-out, transform 0.3s ease-in-out",
						"&:hover": {
							backgroundColor: "error.dark",
							transform: "scale(1.02)",
						},
					}}
					variant="contained"
					onClick={onAgree || onClose}
					autoFocus
				>
					{agreeText}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
