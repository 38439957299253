// import React, { useEffect } from "react";
// import {
// 	TextField,
// 	Button,
// 	Box,
// 	Typography,
// 	CircularProgress,
// 	Divider,
// 	Grid,
// } from "@mui/material";
// import { useMutation } from "react-query";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { createResource, fetcher } from "../../hooks/HttpsRequestHooks";
// import { toast } from "react-toastify";
// import Colors from "../../@colors/Colors";
// import useSWR from "swr";
// import baseUrl from "../../@api/BaseUrls";

// // Define the Yup schema
// const validationSchema = Yup.object({
// 	script: Yup.string().required("Script is required"),
// 	// script2: Yup.string().required("Script 2 is required"),
// });

// const Scripts = () => {
// 	const { data, error, isLoading } = useSWR(`${baseUrl}/scripts/`, fetcher);

// 	// useEffect(() => {
// 	// 	console.log(data);
// 	// }, [data]);
// 	// useMutation hook for handling form submission
// 	const mutation = useMutation(
// 		(data) => createResource("/analysis-script/", data),
// 		{
// 			onSuccess: () => {
// 				toast.success("Script saved successfully!", {
// 					position: "bottom-left",
// 					autoClose: 3000,
// 					hideProgressBar: false,
// 					closeOnClick: true,
// 					pauseOnHover: true,
// 					draggable: true,
// 					progress: undefined,
// 					theme: "colored",
// 				});
// 			},
// 			onError: (error) => {
// 				toast.error(`Error: ${error.message || "Something went wrong"}`, {
// 					position: "bottom-left",
// 					autoClose: 3000,
// 					hideProgressBar: false,
// 					closeOnClick: true,
// 					pauseOnHover: true,
// 					draggable: true,
// 					progress: undefined,
// 					theme: "colored",
// 				});
// 			},
// 		}
// 	);

// 	const formik = useFormik({
// 		initialValues: {
// 			script: "",
// 			// script2: "",
// 		},
// 		validationSchema: validationSchema,
// 		onSubmit: (values) => {
// 			mutation.mutate(values);
// 			// Handle form submission
// 		},
// 	});

// 	return (
// 		<Box
// 			sx={{
// 				display: "flex",
// 				flexDirection: "row",
// 				justifyContent: "space-between",
// 				p: 4,
// 				bgcolor: "background.paper",
// 				boxShadow: 3,
// 				borderRadius: 2,
// 				maxWidth: 1200,
// 				margin: "auto",
// 				gap: 2,
// 			}}
// 		>
// 			{/* Saved Script Section */}
// 			<Box
// 				sx={{
// 					flex: 1,
// 					pr: 2,
// 					display: "flex",
// 					flexDirection: "column",
// 					justifyContent: "center",
// 				}}
// 			>
// 				{/* <Paper elevation={2} sx={{ p: 3, height: "100%", overflowY: "auto" }}> */}
// 				<Box
// 					sx={{ p: 3, height: "100%", maxHeight: "60vh", overflowY: "auto" }}
// 				>
// 					<Typography variant="h6" gutterBottom fontSize={16}>
// 						Current Standerd Script
// 					</Typography>
// 					<Divider sx={{ mb: 2 }} />
// 					<Typography variant="body1" gutterBottom fontSize={13}>
// 						{/* Placeholder text for the saved script */}
// 						{/* {`This is where the saved script will be displayed. Add your script here to show it in this section.`} */}
// 						{data?.script}
// 					</Typography>
// 				</Box>
// 				{/* </Paper> */}
// 			</Box>

// 			{/* Form Section */}
// 			<Box
// 				sx={{
// 					flex: 1,
// 					pl: 2,
// 				}}
// 			>
// 				<Typography variant="h6" gutterBottom fontSize={16}>
// 					Upload standard script
// 				</Typography>
// 				{/* <Paper elevation={2} sx={{ p: 3 }}> */}
// 				<form onSubmit={formik.handleSubmit}>
// 					<Grid container spacing={3}>
// 						<Grid item xs={12}>
// 							{/* <Typography variant="body1" gutterBottom>
// 								Script for analyzing the transcript of the agent.
// 							</Typography> */}
// 							<TextField
// 								name="script"
// 								multiline
// 								rows={15}
// 								fullWidth
// 								variant="outlined"
// 								sx={{ resize: "vertical", bgcolor: "background.default" }} // Allows the textarea to be resized
// 								value={formik.values.script}
// 								onChange={formik.handleChange}
// 								onBlur={formik.handleBlur}
// 								error={formik.touched.script && Boolean(formik.errors.script)}
// 								helperText={formik.touched.script && formik.errors.script}
// 							/>
// 						</Grid>

// 						<Grid item xs={12}>
// 							<Button
// 								variant="contained"
// 								color="primary"
// 								type="submit"
// 								fullWidth
// 								disabled={formik.isSubmitting} // Disable button when submitting
// 								startIcon={
// 									formik.isSubmitting ? (
// 										<CircularProgress size={24} color="inherit" />
// 									) : null
// 								} // Show spinner when submitting
// 								sx={{
// 									mt: 2,
// 									py: 1.5,
// 									fontSize: "1rem",
// 									borderRadius: 1,
// 									bgcolor: Colors.primary,
// 									"&:hover": {
// 										bgcolor: Colors.primaryDark,
// 									},
// 								}}
// 							>
// 								Save
// 							</Button>
// 						</Grid>
// 					</Grid>
// 				</form>
// 				{/* </Paper> */}
// 			</Box>
// 		</Box>
// 	);
// };

// export default Scripts;

import React, { useState } from "react";
import {
	TextField,
	Button,
	Box,
	Typography,
	CircularProgress,
	Divider,
	Grid,
} from "@mui/material";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createResource, fetcher } from "../../hooks/HttpsRequestHooks";
import { toast } from "react-toastify";
import Colors from "../../@colors/Colors";
import useSWR from "swr";
import baseUrl from "../../@api/BaseUrls";

// Define the Yup schema
const validationSchema = Yup.object({
	script: Yup.string().required("Script is required"),
});

const Scripts = () => {
	const [isLoading_, setIsLoading] = useState(false);
	const { data, error, isLoading } = useSWR(`${baseUrl}/scripts/`, fetcher);

	const mutation = useMutation(
		(data) => createResource("/analysis-script/", data),
		{
			onSuccess: () => {
				toast.success("Script saved successfully!", {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				setIsLoading(false);
			},
			onError: (error) => {
				toast.error(`Error: ${error.message || "Something went wrong"}`, {
					position: "bottom-left",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				setIsLoading(false);
			},
		}
	);

	const formik = useFormik({
		initialValues: {
			script: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			setIsLoading(true);
			mutation.mutate(values);
		},
	});

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				p: 4,
				bgcolor: "background.paper",
				boxShadow: 3,
				borderRadius: 2,
				maxWidth: 1200,
				margin: "auto",
				gap: 2,
			}}
		>
			{/* Saved Script Section */}
			<Box
				sx={{
					flex: 1,
					pr: 2,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				<Typography variant="h6" gutterBottom fontSize={16}>
					Current Standard Script
				</Typography>
				<Divider sx={{ mb: 2 }} />
				<Box
					sx={{ p: 3, height: "100%", maxHeight: "60vh", overflowY: "auto" }}
				>
					{isLoading ? (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
							}}
						>
							<CircularProgress />
						</Box>
					) : error ? (
						<Typography variant="body1" color="error">
							Error loading script.
						</Typography>
					) : (
						<Typography variant="body1" gutterBottom fontSize={14}>
							{data?.script}
						</Typography>
					)}
				</Box>
			</Box>

			{/* Form Section */}
			<Box
				sx={{
					flex: 1,
					pl: 2,
				}}
			>
				<Typography variant="h6" gutterBottom fontSize={16}>
					Upload Standard Script
				</Typography>
				<form onSubmit={formik.handleSubmit}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<TextField
								name="script"
								multiline
								rows={15}
								fullWidth
								variant="outlined"
								sx={{ resize: "vertical", bgcolor: "background.default" }}
								value={formik.values.script}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.script && Boolean(formik.errors.script)}
								helperText={formik.touched.script && formik.errors.script}
							/>
						</Grid>

						<Grid item xs={12}>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								fullWidth
								disabled={isLoading_} // Disable button when submitting
								startIcon={
									isLoading_ ? (
										<CircularProgress size={24} color="inherit" />
									) : null
								}
								sx={{
									mt: 2,
									py: 1.5,
									fontSize: "1rem",
									borderRadius: 1,
									bgcolor: Colors.primary,
									"&:hover": {
										bgcolor: Colors.primaryDark,
									},
								}}
							>
								Save
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
		</Box>
	);
};

export default Scripts;
