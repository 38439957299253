import React from "react";
import {
	Container,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Avatar,
	Typography,
	Box,
	IconButton,
	Tooltip,
	Switch,
	Modal,
	FormControl,
	TextField,
	Button,
	CircularProgress,
	TablePagination,
} from "@mui/material";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteConfirmationModal from "./alertcomponents/ConfirmationDialogueBox"; // Import your custom modal component
import { styled } from "@mui/system";

import {
	deepOrange,
	deepPurple,
	green,
	blue,
	red,
	yellow,
	indigo,
	pink,
	teal,
	amber,
	brown,
	cyan,
	lime,
	blueGrey,
	orange,
	lightGreen,
	lightBlue,
	purple,
	grey,
} from "@mui/material/colors";

// Define color for avatars
const colors = [
	deepOrange[500],
	deepPurple[500],
	green[500],
	blue[500],
	red[500],
	yellow[500],
	indigo[500],
	pink[500],
	teal[500],
	amber[500],
	brown[500],
	cyan[500],
	lime[500],
	blueGrey[500],
	orange[500],
	lightGreen[500],
	lightBlue[500],
	purple[500],
	grey[500],
];

// Map each color to a corresponding letter of the English alphabet
const alphabetColors = {};
const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

alphabet.forEach((letter, index) => {
	alphabetColors[letter] = colors[index % colors.length];
});

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	fontSize: "14px",
	fontWeight: "bold",
	color: theme.palette.text.primary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(even)": {
		backgroundColor: theme.palette.action.hover,
	},
}));

const ModalContent = styled(Box)(({ theme }) => ({
	width: 400,
	padding: theme.spacing(2),
	backgroundColor: theme.palette.background.paper,
	boxShadow: theme.shadows[5],
	borderRadius: theme.shape.borderRadius,
}));

const ActionButton = styled(Button)(({ theme }) => ({
	marginRight: theme.spacing(2),
}));

const AccountTable = ({
	columns,
	accountsData,
	isLoading,
	loadingSkeleton,
	handleOpen,
	setLocationId,
	open,
	handleClose,
	accountSid,
	setAccountSid,
	authToken,
	setAuthToken,
	handleSubmit,
	errors,
	postLoading,
	handleSwitchChange,
	checked,
	activeService,
	updateTable,
	pageNo,
	rowsPerPage,
	handleChangePage,
	handleChangeRowsPerPage,
}) => {
	return (
		<Container sx={{ mt: 3 }}>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="accounts table">
					<TableHead>
						<TableRow>
							{columns.map((column, index) => (
								<StyledTableCell key={index}>{column}</StyledTableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading && loadingSkeleton()}
						{accountsData &&
							accountsData[0].locations.map((data, index) => (
								<StyledTableRow key={index}>
									<TableCell>
										<Typography variant="body2" color="text.secondary">
											{index + 1}
										</Typography>
									</TableCell>
									<TableCell>
										<Avatar
											sx={{
												bgcolor:
													alphabetColors[data.first_name[0].toUpperCase()],
												width: 35,
												height: 35,
											}}
										>
											{data.first_name[0].toUpperCase()}
										</Avatar>
									</TableCell>
									<TableCell>
										<Typography variant="body2" color="text.secondary">
											{data.friendly_name}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="body2" color="text.secondary">
											{data.email}
										</Typography>
									</TableCell>

									<TableCell>
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											{data.token ? (
												<VerifiedUserOutlinedIcon
													sx={{ fontSize: 24, color: "green" }}
												/>
											) : (
												<Tooltip title="Add Twilio Account">
													<IconButton
														onClick={() => {
															handleOpen();
															setLocationId(data.locationId);
														}}
													>
														<AddOutlinedIcon
															sx={{ fontSize: 24, color: "primary.main" }} // Use theme color for consistency
														/>
													</IconButton>
												</Tooltip>
											)}
										</Box>
									</TableCell>
									<TableCell>
										<Switch
											disabled={!data.token}
											checked={
												checked === data.locationId
													? activeService
													: data.locationId &&
													  data.service_sid &&
													  data.service_sid !== "-1"
													? true
													: false
											}
											onChange={(event) =>
												handleSwitchChange(event, data.locationId)
											}
											color="success"
											inputProps={{ "aria-label": "controlled" }}
										/>
									</TableCell>
									<TableCell>
										<Typography variant="body2" color="text.secondary">
											{data.locationId}
										</Typography>
									</TableCell>
									<TableCell>
										<Box sx={{ display: "flex", justifyContent: "center" }}>
											<DeleteConfirmationModal
												service_sid={
													data.service_sid === "-1" ? null : data.service_sid
												}
												locationId={data.locationId}
												onSuccessCallback={updateTable}
											/>
										</Box>
									</TableCell>
								</StyledTableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{/* <TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={(accountsData && accountsData[0].pages * rowsPerPage) || 0}
				rowsPerPage={rowsPerPage}
				page={pageNo}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				sx={{
					mt: 2,
					bgcolor: "background.paper", // Background color of the pagination component
					borderRadius: 1, // Rounded corners
					boxShadow: 1, // Subtle shadow for better separation
					display: "flex", // Flex container for alignment
					alignItems: "center", // Center items vertically
					justifyContent: "space-between", // Space out pagination controls
					px: 2, // Padding on the x-axis
					py: 1, // Padding on the y-axis
					"& .MuiTablePagination-select": {
						bgcolor: "background.default", // Background color of the rows per page select
						borderRadius: 1,
						borderColor: "divider", // Border color for the select component
					},
					"& .MuiTablePagination-displayedRows": {
						color: "text.secondary", // Color for the displayed rows text
					},
					"& .MuiTablePagination-actions": {
						color: "text.primary", // Color for the pagination buttons
						"& .MuiIconButton-root": {
							color: "text.primary", // Color for the pagination buttons
							"&:hover": {
								bgcolor: "action.hover", // Background color on hover
							},
						},
					},
				}}
			/> */}

			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={(accountsData && accountsData[0].pages * rowsPerPage) || 0}
				rowsPerPage={rowsPerPage}
				page={pageNo}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				sx={{
					mt: 2,
					bgcolor: "background.paper", // Background color of the pagination component
					borderRadius: 1, // Rounded corners
					boxShadow: 1, // Subtle shadow for better separation
					display: "flex", // Flex container for alignment
					alignItems: "center", // Center items vertically
					justifyContent: "space-between", // Space out pagination controls
					px: 2, // Padding on the x-axis
					py: 1, // Padding on the y-axis
					"& .MuiTablePagination-select": {
						bgcolor: "background.default", // Background color of the rows per page select
						borderRadius: 1,
						borderColor: "divider", // Border color for the select component
					},
					"& .MuiTablePagination-displayedRows": {
						color: "text.secondary", // Color for the displayed rows text
					},
					"& .MuiTablePagination-actions": {
						color: "text.primary", // Color for the pagination buttons
						"& .MuiIconButton-root": {
							color: "text.primary", // Color for the pagination buttons
							"&:hover": {
								bgcolor: "action.hover", // Background color on hover
							},
						},
					},
				}}
			/>

			{/* Modal should be outside of the Table */}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="twilio-account"
				aria-describedby="modal-description"
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					bgcolor: "rgba(0, 0, 0, 0.5)", // Adds a semi-transparent background
				}}
			>
				<Box
					sx={{
						width: 400,
						bgcolor: "background.paper",
						borderRadius: 2,
						boxShadow: 3,
						p: 3,
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						id="twilio-account"
						variant="h6"
						component="h2"
						sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}
					>
						Add Twilio Account
					</Typography>
					<form onSubmit={handleSubmit}>
						<FormControl
							fullWidth
							margin="normal"
							error={!!errors.accountSid}
							sx={{ mb: 2 }}
						>
							<TextField
								id="account-sid"
								variant="outlined"
								label="Account SID"
								value={accountSid}
								onChange={(e) => setAccountSid(e.target.value)}
								helperText={errors.accountSid}
								error={!!errors.accountSid}
								sx={{ bgcolor: "background.default" }}
								size="small"
							/>
						</FormControl>
						<FormControl
							fullWidth
							margin="normal"
							error={!!errors.authToken}
							sx={{ mb: 2 }}
						>
							<TextField
								id="auth-token"
								label="Auth Token"
								variant="outlined"
								value={authToken}
								onChange={(e) => setAuthToken(e.target.value)}
								helperText={errors.authToken}
								error={!!errors.authToken}
								sx={{ bgcolor: "background.default" }}
								size="small"
							/>
						</FormControl>
						<Box
							display="flex"
							justifyContent="flex-end"
							mt={2}
							sx={{ gap: 1 }}
						>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={postLoading} // Disable button while loading
								sx={{ textTransform: "none", padding: "8px 16px" }} // Padding for better clickability
							>
								{postLoading ? (
									<CircularProgress color="inherit" size={24} />
								) : (
									"Add"
								)}
							</Button>
							<Button
								variant="outlined"
								onClick={handleClose}
								sx={{ textTransform: "none", padding: "8px 16px" }}
							>
								Cancel
							</Button>
						</Box>
					</form>
				</Box>
			</Modal>
		</Container>
	);
};

export default AccountTable;
