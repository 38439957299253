// import React, { useEffect, useState } from "react";
// import {
// 	Card,
// 	CardContent,
// 	Typography,
// 	Avatar,
// 	Grid,
// 	Box,
// 	TextField,
// 	Stack,
// 	CircularProgress,
// } from "@mui/material";
// import { useLocation, useNavigate } from "react-router-dom";
// import { usePost } from "../../hooks/HttpsRequestHooks";
// import baseUrl from "../../@api/BaseUrls";
// import { useAuth } from "../../contexts/UserLoginContext";
// import { useFetch } from "../../hooks/HttpsRequestHooks";

// const UserDisplay = () => {
// 	const [is_admin, setIs_admin] = useState(null);

// 	const [openai, setOpenai] = useState("");
// 	const [clientId, setClientId] = useState("");
// 	const [clientSecret, setClientSecret] = useState("");
// 	const [accountSid, setAccountSid] = useState("");
// 	const [authToken, setAuthToken] = useState("");
// 	const { data: response, error, loading, postData } = usePost();
// 	const {
// 		data: credential_data,
// 		error: credential_error,
// 		loading: credential_loading,
// 		fetchData,
// 	} = useFetch(`${baseUrl}/credentials/`);
// 	const { user } = useAuth();
// 	const [code, setCode] = useState("");
// 	const location = useLocation();
// 	const navigate = useNavigate();

// 	useEffect(() => {
// 		const user_json = localStorage.getItem("houzoo_user_data");
// 		if (user_json) {
// 			const user_data = JSON.parse(user_json);
// 			setIs_admin(user_data?.is_admin);
// 		}
// 	}, []);
// 	useEffect(() => {
// 		if (location?.search) {
// 			const param_value = location.search.split("=")[1];
// 			setCode(param_value);
// 			try {
// 				const response = postData(`${baseUrl}/auth`, {
// 					code,
// 				});
// 				console.log(response);
// 			} catch (error) {
// 				console.log(error);
// 			}
// 		}

// 		// if (location?.search) {
// 		// 	// Removing the parameter
// 		// 	const params = new URLSearchParams(location.search);
// 		// 	params.delete("code");
// 		// 	navigate(
// 		// 		{
// 		// 			pathname: location.pathname,
// 		// 			search: params.toString(),
// 		// 		},
// 		// 		{ replace: true }
// 		// 	);
// 		// }
// 	}, [location, code]);

// 	useEffect(() => {
// 		if (credential_data) {
// 			for(const item of credential_data.data) {
// 				switch (item.key) {
// 					case "client_id":
// 						setClientId(item.value);
// 						break;
// 					case "client_secret":
// 						setClientSecret(item.value);
// 						break;
// 					case "openai_api_key":
// 						setOpenai(item.value);
// 						break;
// 					case "twilio_account_sid":
// 						setAccountSid(item.value);
// 						break;
// 					case "twilio_auth_token":
// 						setAuthToken(item.value);
// 						break;
// 					default:
// 						break;
// 				}
// 			}
// 		}
// 	},[credential_data]);

// 	return (
// 		<Box
// 			sx={{
// 				padding: "2% 4%",
// 			}}
// 		>
// 			<Card
// 				style={{
// 					minWidth: 100,
// 					boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
// 					borderRadius: 12,
// 					padding: "2% 4%",
// 				}}
// 			>
// 				<CardContent>
// 					<Grid container spacing={2}>
// 						<Grid item xs={12} style={{ textAlign: "center" }}>
// 							{false ? (
// 								<Avatar
// 									src={""}
// 									alt={""}
// 									style={{
// 										width: 80,
// 										height: 80,
// 										backgroundColor: "#3f51b5",
// 										margin: "0 auto",
// 									}}
// 								/>
// 							) : (
// 								<Avatar
// 									style={{
// 										width: 100,
// 										height: 100,
// 										backgroundColor: "#3f51b5",
// 										margin: "0 auto",
// 									}}
// 								>
// 									{user?.first_name.charAt(0).toUpperCase()}
// 								</Avatar>
// 							)}
// 						</Grid>
// 						<Grid item xs={12} style={{ textAlign: "center" }}>
// 							<Typography
// 								variant="h5"
// 								component="div"
// 								style={{ fontWeight: "bold", color: "#333" }}
// 							>
// 								{user?.first_name + " " + user?.last_name}
// 							</Typography>
// 							<Typography
// 								variant="body2"
// 								color="textSecondary"
// 								style={{ color: "#777" }}
// 							>
// 								{user?.email}
// 							</Typography>
// 						</Grid>
// 					</Grid>
// 				</CardContent>
// 				{is_admin !== 0 && (
// 					<Box sx={{ mt: "5%" }}>
// 						<Typography sx={{ fontWeight: "bold", mb: "1%" }}>
// 							OpenAi Secrets
// 						</Typography>
// 						<Stack gap={0.5}>
// 							<Typography>
// 								{"OpenAi Key  "}
// 								{credential_loading && (
// 									<CircularProgress size="15px" color="secondary" />
// 								)}
// 							</Typography>

// 							<TextField
// 								size="small"
// 								id="outlined-basic"
// 								placeholder="open ai key"
// 								value={openai}
// 								onChange={(e) => setOpenai(e.target.value)}
// 							/>
// 						</Stack>
// 						<Stack sx={{ mt: "5%", gap: 1 }}>
// 							<Typography sx={{ fontWeight: "bold", mb: "1%" }}>
// 								CRM Secrets
// 							</Typography>
// 							<Stack gap={0.5}>
// 								<Typography>
// 									{"Client Secret "}
// 									{credential_loading && (
// 										<CircularProgress size="15px" color="secondary" />
// 									)}
// 								</Typography>
// 								<TextField
// 									size="small"
// 									id="outlined-basic"
// 									placeholder="open ai key"
// 									value={clientSecret}
// 									onChange={(e) => setClientSecret(e.target.value)}
// 								/>
// 							</Stack>
// 							<Stack gap={0.5}>
// 								<Typography>
// 									{"Client ID  "}
// 									{credential_loading && (
// 										<CircularProgress size="15px" color="secondary" />
// 									)}
// 								</Typography>
// 								<TextField
// 									size="small"
// 									id="outlined-basic"
// 									placeholder="open ai key"
// 									value={clientId}
// 									onChange={(e) => setClientId(e.target.value)}
// 								/>
// 							</Stack>
// 						</Stack>
// 						<Stack sx={{ mt: "5%", gap: 1 }}>
// 							<Typography sx={{ fontWeight: "bold", mb: "1%" }}>
// 								Twilio Secrets
// 							</Typography>
// 							<Stack gap={0.5}>
// 								<Typography>
// 									{"Account SID "}
// 									{credential_loading && (
// 										<CircularProgress size="15px" color="secondary" />
// 									)}
// 								</Typography>
// 								<TextField
// 									size="small"
// 									id="outlined-basic"
// 									placeholder="open ai key"
// 									value={accountSid}
// 									onChange={(e) => setClientSecret(e.target.value)}
// 								/>
// 							</Stack>
// 							<Stack gap={0.5}>
// 								<Typography>
// 									{"Auth Token "}
// 									{credential_loading && (
// 										<CircularProgress size="15px" color="secondary" />
// 									)}
// 								</Typography>
// 								<TextField
// 									size="small"
// 									id="outlined-basic"
// 									placeholder="open ai key"
// 									value={authToken}
// 									onChange={(e) => setClientId(e.target.value)}
// 								/>
// 							</Stack>
// 						</Stack>
// 					</Box>
// 				)}
// 			</Card>
// 		</Box>
// 	);
// };

// export default UserDisplay;

import React, { useEffect, useState } from "react";
import {
	Card,
	CardContent,
	Typography,
	Avatar,
	Grid,
	Box,
	TextField,
	Stack,
	CircularProgress,
	IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff, FileCopy } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { usePost } from "../../hooks/HttpsRequestHooks";
import baseUrl from "../../@api/BaseUrls";
import { useAuth } from "../../contexts/UserLoginContext";
import { useFetch } from "../../hooks/HttpsRequestHooks";

const UserDisplay = () => {
	const [is_admin, setIs_admin] = useState(null);

	const [openai, setOpenai] = useState("");
	const [clientId, setClientId] = useState("");
	const [clientSecret, setClientSecret] = useState("");
	const [accountSid, setAccountSid] = useState("");
	const [authToken, setAuthToken] = useState("");
	const { data: response, error, loading, postData } = usePost();
	const {
		data: credential_data,
		error: credential_error,
		loading: credential_loading,
		fetchData,
	} = useFetch(`${baseUrl}/credentials/`);
	const { user } = useAuth();
	const [code, setCode] = useState("");
	const location = useLocation();
	const navigate = useNavigate();
	const [showSecrets, setShowSecrets] = useState(false);

	useEffect(() => {
		const user_json = localStorage.getItem("houzoo_user_data");
		if (user_json) {
			const user_data = JSON.parse(user_json);
			setIs_admin(user_data?.is_admin);
		}
	}, []);

	useEffect(() => {
		if (location?.search) {
			const param_value = location.search.split("=")[1];
			setCode(param_value);
			try {
				const response = postData(`${baseUrl}/auth`, {
					code,
				});
			} catch (error) {
				console.log(error);
			}
		}
	}, [location, code]);

	useEffect(() => {
		if (credential_data) {
			for (const item of credential_data.data) {
				switch (item.key) {
					case "client_id":
						setClientId(item.value);
						break;
					case "client_secret":
						setClientSecret(item.value);
						break;
					case "openai_api_key":
						setOpenai(item.value);
						break;
					case "twilio_account_sid":
						setAccountSid(item.value);
						break;
					case "twilio_auth_token":
						setAuthToken(item.value);
						break;
					default:
						break;
				}
			}
		}
	}, [credential_data]);

	const handleCopyToClipboard = (value) => {
		navigator.clipboard.writeText(value);
	};

	const toggleVisibility = () => {
		setShowSecrets(!showSecrets);
	};

	const renderSecretField = (label, value, setValue) => (
		<Stack gap={0.5} key={label} width="100%">
			<Typography>
				{label}
				{"  "}
				{credential_loading && (
					<CircularProgress size="15px" color="secondary" />
				)}
			</Typography>
			<TextField
				size="small"
				placeholder={label}
				width="100%"
				value={showSecrets ? value : "•".repeat(value.length)}
				onChange={(e) => setValue(e.target.value)}
				InputProps={{
					endAdornment: (
						<Stack direction="row" spacing={1} alignItems="center">
							<IconButton
								onClick={() => handleCopyToClipboard(value)}
								size="small"
							>
								<FileCopy />
							</IconButton>
							<IconButton onClick={toggleVisibility} size="small">
								{showSecrets ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</Stack>
					),
				}}
			/>
		</Stack>
	);

	return (
		<Box sx={{ padding: "2% 4%" }}>
			<Card
				style={{
					minWidth: 100,
					boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
					borderRadius: 12,
					padding: "2% 4%",
				}}
			>
				<CardContent>
					<Grid container spacing={2}>
						<Grid item xs={12} style={{ textAlign: "center" }}>
							{false ? (
								<Avatar
									src={""}
									alt={""}
									style={{
										width: 80,
										height: 80,
										backgroundColor: "#3f51b5",
										margin: "0 auto",
									}}
								/>
							) : (
								<Avatar
									style={{
										width: 100,
										height: 100,
										backgroundColor: "#3f51b5",
										margin: "0 auto",
									}}
								>
									{user?.first_name.charAt(0).toUpperCase()}
								</Avatar>
							)}
						</Grid>
						<Grid item xs={12} style={{ textAlign: "center" }}>
							<Typography
								variant="h5"
								component="div"
								style={{ fontWeight: "bold", color: "#333" }}
							>
								{user?.first_name + " " + user?.last_name}
							</Typography>
							<Typography
								variant="body2"
								color="textSecondary"
								style={{ color: "#777" }}
							>
								{user?.email}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
				{is_admin !== 0 && (
					<Box sx={{ mt: "5%" }}>
						<Typography sx={{ fontWeight: "bold", mb: "1%" }}>
							OpenAi Secrets
						</Typography>
						{renderSecretField("OpenAi Key", openai, setOpenai)}

						<Typography sx={{ fontWeight: "bold", mt: "5%", mb: "1%" }}>
							CRM Secrets
						</Typography>
						<Stack direction="row" gap={2}>
							{renderSecretField(
								"Client Secret",
								clientSecret,
								setClientSecret
							)}
							{renderSecretField("Client ID", clientId, setClientId)}
						</Stack>

						<Typography sx={{ fontWeight: "bold", mt: "5%", mb: "1%" }}>
							Twilio Secrets
						</Typography>
						<Stack direction="row" gap={2}>
							{renderSecretField("Account SID", accountSid, setAccountSid)}
							{renderSecretField("Auth Token", authToken, setAuthToken)}
						</Stack>
					</Box>
				)}
			</Card>
		</Box>
	);
};

export default UserDisplay;
