import { CircularProgress, Paper } from "@mui/material";
import { useState, useEffect } from "react";
import ApexCharts from "react-apexcharts";
import Colors from "../@colors/Colors";

const ComboChart = ({ data, loading }) => {
	const [dates, setDates] = useState([]);
	const [frequencyData, setFrequencyData] = useState({
		AppointmentSetting: [],
		SynonymUsage: [],
		WordForWordMatch: [],
		WordForWordWithChangedOrder: [],
	});
	const [callDurations, setCallDurations] = useState([]);

	useEffect(() => {
		const setDatas = () => {
			try {
				const parsedData = data?.data?.map((item) => {
					const date = new Date(item.date_created);
					date.setHours(date.getHours() - 4);
					return {
						date: date,
						appointmentSetting: Number(item.AppointmentSetting),
						synonymUsage: Number(item.SynonymUsage),
						wordForWordMatch: Number(item.WordForWordMatch),
						wordForWordWithChangedOrder: Number(
							item.WordForWordWithChangedOrder
						),
						callDuration: Number(item.duration ),
					};
				});

				// Sort data by date
				parsedData?.sort((a, b) => a.date - b.date);

				// Extract sorted arrays
				const datesArray = parsedData?.map((item) =>
					item.date.toLocaleDateString("en-US", {
						month: "short",
						day: "numeric",
					})
				);
				const appointmentSetting = parsedData?.map(
					(item) => item.appointmentSetting
				);
				const synonymUsage = parsedData?.map((item) => item.synonymUsage);
				const wordForWordMatch = parsedData?.map(
					(item) => item.wordForWordMatch
				);
				const wordForWordWithChangedOrder = parsedData?.map(
					(item) => item.wordForWordWithChangedOrder
				);
				const callDurationsArray = parsedData?.map((item) => item.callDuration);

				setDates(datesArray);
				setFrequencyData({
					AppointmentSetting: appointmentSetting,
					SynonymUsage: synonymUsage,
					WordForWordMatch: wordForWordMatch,
					WordForWordWithChangedOrder: wordForWordWithChangedOrder,
				});
				setCallDurations(callDurationsArray);
			} catch (error) {
				console.error("Error processing data:", error);
			}
		};

		setDatas();
	}, [data]);

	const options = {
		chart: {
			type: "line",
			stacked: false,
			toolbar: {
				show: false,
			},
			// zoom: {
			// 	enabled: false,
			// },
		},
		stroke: {
			curve: "smooth",
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: "50%",
			},
		},
		xaxis: {
			categories: dates,
			type: "category",
		},
		yaxis: [
			{
				title: {
					text: "Frequency",
				},
			},
			{
				opposite: true,
				title: {
					text: "Call Duration",
				},
			},
		],
		series: [
			// {
			// 	name: "Exact Word Match",
			// 	type: "column",
			// 	data: frequencyData.AppointmentSetting,
			// },
			// {
			// 	name: "Literal Rearrangement",
			// 	type: "column",
			// 	data: frequencyData.SynonymUsage,
			// 	color: "#008FFB",
			// },
			// {
			// 	name: "Synonym Usage",
			// 	type: "column",
			// 	data: frequencyData.WordForWordMatch,
			// 	color: "#FF4560",
			// },
			// {
			// 	name: "Appointment Setting",
			// 	type: "column",
			// 	data: frequencyData.WordForWordWithChangedOrder,
			// 	color: "#775DD0",
			// },
			{
				name: "Exact Word Match",
				type: "column",
				data: frequencyData.AppointmentSetting,
				color: "#1E90FF", // Dodger Blue
			},
			{
				name: "Literal Rearrangement",
				type: "column",
				data: frequencyData.SynonymUsage,
				color: "#FF6347", // Tomato
			},
			{
				name: "Synonym Usage",
				type: "column",
				data: frequencyData.WordForWordMatch,
				color: "#7A918D", // Deep Pink
			},
			{
				name: "Appointment Setting",
				type: "column",
				data: frequencyData.WordForWordWithChangedOrder,
				color: "#FFD700", // Gold
			},
			{
				name: "Call Duration",
				type: "area",
				data: callDurations,
				color: Colors.primary,
			},
		],
		legend: {
			position: "top",
			horizontalAlign: "left",
		},
	};

	// const options = {
	// 	chart: {
	// 		type: "line",
	// 		stacked: false,
	// 		toolbar: {
	// 			show: false,
	// 		},
	// 		zoom: {
	// 			enabled: false,
	// 		},
	// 	},
	// 	stroke: {
	// 		curve: "smooth",
	// 	},
	// 	plotOptions: {
	// 		bar: {
	// 			horizontal: false,
	// 			columnWidth: "50%",
	// 		},
	// 	},
	// 	xaxis: {
	// 		categories: dates,
	// 		type: "category", // Use category for custom labels
	// 	},
	// 	yaxis: {
	// 		title: {
	// 			text: "Frequency",
	// 		},
	// 	},
	// 	series: [
	// 		{
	// 			name: "Exact Word Match",
	// 			type: "column",
	// 			data: frequencyData.AppointmentSetting,
	// 		},
	// 		{
	// 			name: "Literal Rearrangement",
	// 			type: "column",
	// 			data: frequencyData.SynonymUsage,
	// 		},
	// 		{
	// 			name: "Synonym Usage",
	// 			type: "column",
	// 			data: frequencyData.WordForWordMatch,
	// 		},
	// 		{
	// 			name: "Appointment Setting",
	// 			type: "column",
	// 			data: frequencyData.WordForWordWithChangedOrder,
	// 		},
	// 		{
	// 			name: "Call Duration",
	// 			type: "area",
	// 			data: callDurations,
	// 		},
	// 	],
	// 	legend: {
	// 		position: "top",
	// 		horizontalAlign: "left",
	// 	},
	// };

	return (
		<div className="mixed-chart">
			{loading && (
				<Paper
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						backgroundColor: "rgba(0, 0, 0, 0.2)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 999,
					}}
				>
					<CircularProgress color="inherit" />
				</Paper>
			)}
			<ApexCharts
				options={options}
				series={options.series}
				type="line" // Type can be 'line' since it's mixed
				height={400}
			/>
		</div>
	);
};

export default ComboChart;

// import { CircularProgress, Paper } from "@mui/material";
// import { useState, useEffect } from "react";
// import ApexCharts from "react-apexcharts";
// import dayjs from "dayjs"; // Import dayjs for date handling

// const ComboChart = ({ data, loading }) => {
// 	const [dates, setDates] = useState([]);
// 	const [frequencyData, setFrequencyData] = useState({
// 		AppointmentSetting: [],
// 		SynonymUsage: [],
// 		WordForWordMatch: [],
// 		WordForWordWithChangedOrder: [],
// 	});
// 	const [callDurations, setCallDurations] = useState([]);

// 	useEffect(() => {
// 		const setDatas = () => {
// 			try {
// 				const parsedData = data?.data?.map((item) => {
// 					// Using dayjs to parse date without timezone conversion
// 					const date = dayjs(item.date_created).format("YYYY-MM-DD");

// 					console.log("Original Date >>  ", item.date_created);
// 					console.log("Parsed Date >>  ", date);

// 					return {
// 						date: date, // Keeping date as-is from server
// 						appointmentSetting: Number(item.AppointmentSetting),
// 						synonymUsage: Number(item.SynonymUsage),
// 						wordForWordMatch: Number(item.WordForWordMatch),
// 						wordForWordWithChangedOrder: Number(
// 							item.WordForWordWithChangedOrder
// 						),
// 						callDuration: Number(item.duration / 5),
// 					};
// 				});

// 				// Sort data by date
// 				parsedData.sort((a, b) =>
// 					dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
// 				);

// 				// Extract sorted arrays
// 				const datesArray = parsedData.map((item) =>
// 					dayjs(item.date).format("MMM D")
// 				);

// 				const appointmentSetting = parsedData.map(
// 					(item) => item.appointmentSetting
// 				);
// 				const synonymUsage = parsedData.map((item) => item.synonymUsage);
// 				const wordForWordMatch = parsedData.map(
// 					(item) => item.wordForWordMatch
// 				);
// 				const wordForWordWithChangedOrder = parsedData.map(
// 					(item) => item.wordForWordWithChangedOrder
// 				);
// 				const callDurationsArray = parsedData.map((item) => item.callDuration);

// 				setDates(datesArray);
// 				setFrequencyData({
// 					AppointmentSetting: appointmentSetting,
// 					SynonymUsage: synonymUsage,
// 					WordForWordMatch: wordForWordMatch,
// 					WordForWordWithChangedOrder: wordForWordWithChangedOrder,
// 				});
// 				setCallDurations(callDurationsArray);
// 			} catch (error) {
// 				console.error("Error processing data:", error);
// 			}
// 		};

// 		setDatas();
// 	}, [data]);

// 	const options = {
// 		chart: {
// 			type: "line",
// 			stacked: false,
// 			toolbar: {
// 				show: false,
// 			},
// 			zoom: {
// 				enabled: false,
// 			},
// 		},
// 		stroke: {
// 			curve: "smooth",
// 		},
// 		plotOptions: {
// 			bar: {
// 				horizontal: false,
// 				columnWidth: "50%",
// 			},
// 		},
// 		xaxis: {
// 			categories: dates,
// 			type: "category", // Use category for custom labels
// 		},
// 		yaxis: {
// 			title: {
// 				text: "Frequency",
// 			},
// 		},
// 		series: [
// 			{
// 				name: "Exact Word Match",
// 				type: "column",
// 				data: frequencyData.AppointmentSetting,
// 			},
// 			{
// 				name: "Literal Rearrangement",
// 				type: "column",
// 				data: frequencyData.SynonymUsage,
// 			},
// 			{
// 				name: "Synonym Usage",
// 				type: "column",
// 				data: frequencyData.WordForWordMatch,
// 			},
// 			{
// 				name: "Appointment Setting",
// 				type: "column",
// 				data: frequencyData.WordForWordWithChangedOrder,
// 			},
// 			{
// 				name: "Call Duration",
// 				type: "area",
// 				data: callDurations,
// 			},
// 		],
// 		legend: {
// 			position: "top",
// 			horizontalAlign: "left",
// 		},
// 	};

// 	return (
// 		<div className="mixed-chart">
// 			{loading && (
// 				<Paper
// 					style={{
// 						position: "absolute",
// 						top: 0,
// 						left: 0,
// 						width: "100%",
// 						height: "100%",
// 						backgroundColor: "rgba(0, 0, 0, 0.2)",
// 						display: "flex",
// 						justifyContent: "center",
// 						alignItems: "center",
// 						zIndex: 999,
// 					}}
// 				>
// 					<CircularProgress color="inherit" />
// 				</Paper>
// 			)}
// 			<ApexCharts
// 				options={options}
// 				series={options.series}
// 				type="line" // Type can be 'line' since it's mixed
// 				height={400}
// 			/>
// 		</div>
// 	);
// };

// export default ComboChart;
