// PrivateRoute.js

import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "../utilities/TokenSetting";

const RoleBasedRoute = ({ allowedRoles, children }) => {
	const user = JSON.parse(localStorage.getItem("houzoo_user_data"));
	const isAuthorized = allowedRoles.includes(user.is_admin);
	return isAuthorized ? children : <Navigate to="/scoring-model" />;
};

const PrivateRoute = () => {
	const token = getToken();
	const isAuthenticated = token ? true : false;
	return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
export { RoleBasedRoute };
