import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, IconButton, Typography, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/UserLoginContext";
import Colors from "../@colors/Colors";
import ScoringModelIcon from "@mui/icons-material/ModelTraining";
import QueryStatsSharpIcon from "@mui/icons-material/QueryStatsSharp";
import LibraryBooksSharpIcon from "@mui/icons-material/LibraryBooksSharp";
import LockOpenSharpIcon from "@mui/icons-material/LockOpenSharp";
import PeopleSharpIcon from "@mui/icons-material/PeopleSharp";
import AccountAvatar from "./UserProfiloAvatar";

const Navbar = () => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const [menu, setMenu] = useState([]);
	const [activeLink, setActiveLink] = useState("");

	useEffect(() => {
		if (user?.is_admin) {
			setMenu([
				{
					name: "Scoring Model",
					link: "/scoring-model",
					icon: <QueryStatsSharpIcon />,
				},
				{ name: "Sub Accounts", link: "/locations", icon: <PeopleSharpIcon /> },
				{ name: "Authentication", link: "/auth", icon: <LockOpenSharpIcon /> },
				{ name: "Scripts", link: "/scripts", icon: <LibraryBooksSharpIcon /> },
			]);
		} else {
			setMenu([
				{
					name: "Scoring Model",
					link: "/scoring-model",
					icon: <ScoringModelIcon />,
				},
				{ name: "Scripts", link: "/scripts", icon: <LibraryBooksSharpIcon /> },
			]);
		}
	}, [user]);

	useEffect(() => {
		setActiveLink(location.pathname);
		localStorage.setItem("activeLink", location.pathname);
	}, [location]);

	const handleNavigation = (link) => {
		navigate(link);
	};

	return (
		<>
			<AppBar
				position="fixed"
				sx={{
					backgroundColor: Colors.off,
					color: Colors.neutral,
					height: "60px",

					boxShadow: "none",
					borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
				}}
			>
				<Toolbar>
					{menu.map((item) => (
						<IconButton
							key={item.name}
							onClick={() => handleNavigation(item.link)}
							sx={{
								borderRadius: "4px",
								color: Colors.secondary,
								px: 2,
								marginX: "1px",
								...(item.link === activeLink && {
									backgroundColor: Colors.primary,
									borderRadius: "4px",
									color: Colors.neutral,
								}),
								"&:hover": {
									backgroundColor: Colors.primary,
									color: Colors.neutral,
									borderRadius: "4px",
								},
							}}
						>
							{item.icon}
							<Typography
								variant="button"
								sx={{ ml: "5px", textTransform: "none" }}
							>
								{item.name}
							</Typography>
						</IconButton>
					))}
					<Box
						sx={{
							height: "fit-content",
							width: "100%",
							display: "flex",
							gap: "5px",
							justifyContent: "flex-end",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								textAlign: "end",
							}}
						>
							<Typography
								sx={{
									color: Colors.secondary,
									fontSize: "15px",
								}}
							>
								{user?.friendly_name ||
									user?.first_name + " " + user?.last_name}
							</Typography>
							<Typography sx={{ color: Colors.secondary, fontSize: "11px" }}>
								{user?.is_admin ? "Admin" : "User"}
							</Typography>
						</Box>
						<AccountAvatar user={user} />
					</Box>
				</Toolbar>
			</AppBar>
		</>
	);
};

export default Navbar;
