import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Colors from "../@colors/Colors";
import { IconButton } from "@mui/material";

const CustomTextField = ({
	onValueChange,
	size = "small",
	width = "100%",
	placeholder,
	isPassword = false,
}) => {
	const [value, setValue] = React.useState("");
	const [showPassword, setShowPassword] = React.useState(false);

	const handleChange = (event) => {
		const newValue = event.target.value;
		setValue(newValue);
		if (onValueChange) {
			onValueChange(newValue);
		}
	};

	const handlePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const getIcon = () => {
		if (!placeholder) return null;
		if (placeholder.toLowerCase() === "email") {
			return <EmailIcon style={{ color: Colors.primary }} />;
		} else if (isPassword) {
			return showPassword ? (
				<IconButton sx={{ m: 0, p: 0 }} onClick={handlePasswordVisibility}>
					<VisibilityOffIcon style={{ color: Colors.primary }} />
				</IconButton>
			) : (
				<IconButton sx={{ m: 0, p: 0 }} onClick={handlePasswordVisibility}>
					<VisibilityIcon style={{ color: Colors.primary }} />
				</IconButton>
			);
		}
		return null;
	};

	return (
		<TextField
			sx={{
				"& > :not(style)": { width: width || "100%" },
				"& .MuiOutlinedInput-root": {
					"&:hover fieldset": {
						borderColor: Colors.primary,
					},
					"&.Mui-focused fieldset": {
						borderColor: Colors.primary,
					},
				},
			}}
			style={{
				color: Colors.primary,
			}}
			size={size}
			id="outlined-basic"
			variant="outlined"
			value={value}
			placeholder={placeholder}
			onChange={handleChange}
			InputProps={
				getIcon() && {
					endAdornment: (
						<InputAdornment position="end">
							{getIcon()}
							{/* </IconButton> */}
						</InputAdornment>
					),
				}
			}
			type={isPassword ? (showPassword ? "text" : "password") : "email"} // Toggle password type based on state
		/>
	);
};

export default CustomTextField;
