import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/Routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./App.css";

const queryClient = new QueryClient();

const theme = createTheme({
	// Define your custom theme here if needed
	palette: {
		text: {
			primary: "#000", // Default primary text color
			secondary: "#666", // Default secondary text color
		},
	},
	typography: {
		fontFamily: "Roboto, Arial, sans-serif",
	},
});

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<Router>
					<AppRoutes />
				</Router>
			</ThemeProvider>
		</QueryClientProvider>
	);
}

export default App;
