import React, { createContext, useState, useContext, useEffect } from "react";
import { setToken } from "../utilities/TokenSetting";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);

	const login = (userData) => {
		localStorage.setItem("houzoo_user_data", JSON.stringify(userData.data));
		setUser(userData?.data);
		setToken(userData?.token);
	};

	useEffect(() => {
		const userData = localStorage.getItem("houzoo_user_data");
		if (!user) {
			setUser(JSON.parse(userData));
		}
	}, [user]);
	const logout = () => {
		setUser(null);
	};

	return (
		<AuthContext.Provider value={{ user, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);

const CrmUserContext = createContext();

export const CrmUserContextProvider = ({ children }) => {
	const [crm_user, setUser] = useState(null);

	const userData = (user_data) => {
		localStorage.setItem("crm_user_data", JSON.stringify(user_data));
		setUser(user_data);
	};

	useEffect(() => {
		const userData = localStorage.getItem("crm_user_data");
		if (!crm_user) {
			setUser(JSON.parse(userData));
		}
	}, [crm_user]);
	const logout = () => {
		setUser(null);
		localStorage.removeItem("crm_user_data");
	};

	return (
		<CrmUserContext.Provider value={{ crm_user, userData, logout }}>
			{children}
		</CrmUserContext.Provider>
	);
};

export const useCrmUser = () => useContext(CrmUserContext);
