import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Login from "../@modules/Users/Login";
import PrivateRoute from "./Private";
import MainLayout from "../@modules/MainLayout";
import {
	AuthProvider,
	CrmUserContextProvider,
} from "../contexts/UserLoginContext";
import Authentication from "../@components/GoHighLevel";
import UserDisplay from "../@modules/Users/Profile";

import { RoleBasedRoute } from "./Private";
import Locations from "../@modules/Dashboard/Locations";

import CallTranscriptionStatisticsPage from "../@modules/Dashboard/CallTranscriptionStatisticsPage";
import { getToken } from "../utilities/TokenSetting";

import Scripts from "../@modules/Dashboard/Scripts";

function AppRoutes() {
	useEffect(() => {
		async function checkToken() {
			const token = await getToken();
			if (window.location.href === "/" && !token) {
				window.location.href = "/login";
			}
		}

		checkToken();
	}, []);

	return (
		// <Router>
		<>
			<AuthProvider>
				<CrmUserContextProvider>
					<Routes>
						<Route path="/login/:location_id?/:sessionKey?" element={<Login />} />
						<Route path="/" element={<PrivateRoute />}>
							<Route path="/" element={<MainLayout />}>
								<Route
									path="scoring-model"
									element={
										<RoleBasedRoute allowedRoles={[1, 0]}>
											<CallTranscriptionStatisticsPage />
										</RoleBasedRoute>
									}
								/>
								<Route
									path="locations"
									element={
										<RoleBasedRoute allowedRoles={[1]}>
											<Locations />
										</RoleBasedRoute>
									}
								/>

								<Route
									path="auth"
									element={
										<RoleBasedRoute allowedRoles={[1]}>
											<Authentication />
										</RoleBasedRoute>
									}
								/>
								<Route
									path="scripts"
									element={
										<RoleBasedRoute allowedRoles={[1, 0]}>
											<Scripts />
										</RoleBasedRoute>
									}
								/>
								<Route path="profile" element={<UserDisplay />} />
							</Route>
						</Route>
					</Routes>
				</CrmUserContextProvider>
			</AuthProvider>
		</>
		// </Router>
	);
}

export default AppRoutes;
