import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import SidebarMenu from "../@components/SideBarMenu";
import Navbar from "../@components/SideBarMenu";
import { useFetch } from "../hooks/HttpsRequestHooks";
import baseUrl from "../@api/BaseUrls";
import { useCrmUser } from "../contexts/UserLoginContext";
import AccountAvatar from "../@components/UserProfiloAvatar";
import Colors from "../@colors/Colors";
import { useAuth } from "../contexts/UserLoginContext";
import { ToastContainer } from "react-toastify";


const MainLayout = () => {
	const { user } = useAuth();
	const { data } = useFetch(`${baseUrl}/current-user`);
	// Current user for CRM GHL
	const { userData } = useCrmUser();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (location.pathname === "/") {
			navigate("/locations");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (data) {
			userData(data);
		}
	});
	return (
		<Box>
			<Navbar />

			<Box
				component="main"
				sx={{
					mt: "70px",
				}}
				// Criticle area for styling. MAIN LAYOUT POSISION
			>
				{/* <Box
					sx={{
						// backgroundColor: Colors.off,
						position: "sticky",
						px: "50px",
						boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					}}
				>
					<Box
						sx={{
							mt: "auto",
							width: "100%",
							display: "flex",
							// flexDirection: "column",
							gap: "5px",
							justifyContent: "flex-end",
							alignItems: "center",
						}}
					>
						<Box>
							<Typography
								sx={{
									color: Colors.secondary,
									// fontWeight: "bold",
									fontSize: "15px",
								}}
							>
								{user?.first_name}
							</Typography>
							<Typography sx={{ color: Colors.secondary, fontSize: "11px" }}>
								{user?.is_admin ? "Admin" : "User"}
							</Typography>
						</Box>
						<AccountAvatar user={user} />
					</Box>
				</Box> */}
				<Outlet />
			</Box>
			<ToastContainer />
		</Box>
	);
};

export default MainLayout;
