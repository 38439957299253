// import * as React from "react";
// import { toast } from "react-toastify";
// import { useMutation } from "react-query";
// import { createResource } from "../hooks/HttpsRequestHooks";
// import { useFormik } from "formik";
// import Colors from "../@colors/Colors";
// import * as Yup from "yup";
// import {
// 	Backdrop,
// 	Box,
// 	Modal,
// 	Fade,
// 	Button,
// 	Typography,
// 	CircularProgress,
// 	Stack,
// 	TextField,
// 	Tooltip,
// } from "@mui/material";

// const style = {
// 	position: "absolute",
// 	top: "50%",
// 	left: "50%",
// 	transform: "translate(-50%, -50%)",
// 	width: 600,
// 	bgcolor: "background.paper",
// 	boxShadow: 24,
// 	p: 4,
// };

// // Validation schema with Yup
// const validationSchema = Yup.object({
// 	locationId: Yup.string(),
// 	firstName: Yup.string().required("First Name is required"),
// 	lastName: Yup.string().required("Last Name is required"),
// 	name: Yup.string().required("Name is required"),
// 	email: Yup.string()
// 		.email("Invalid email format")
// 		.required("Email is required"),
// 	password: Yup.string().required("Password is required"),
// });

// const modalStyle = {
// 	position: "absolute",
// 	top: "50%",
// 	left: "50%",
// 	transform: "translate(-50%, -50%)",
// 	width: 450,
// 	bgcolor: "background.paper",
// 	boxShadow: 24,
// 	p: 4,
// 	borderRadius: 2,
// };

// export default function AddLocation({ option, onSuccessCallback }) {
// 	const [open, setOpen] = React.useState(false);

// 	const mutation = useMutation((data) => createResource(`/locations/`, data), {
// 		onSuccess: () => {
// 			showToast(201);
// 			handleClose();
// 			onSuccessCallback(true); // Notify parent of success
// 		},
// 		onError: (error) => {
// 			showToast(error.status || 500);
// 		},
// 	});

// 	const formik = useFormik({
// 		initialValues: {
// 			locationId: option?.id || "",
// 			firstName: option?.firstName || "",
// 			lastName: option?.lastName || "",
// 			name: option?.name || "",
// 			email: option?.email || "",
// 			password: option?.password || "",
// 		},
// 		validationSchema,
// 		onSubmit: (values) => {
// 			mutation.mutate({
// 				locationId: values.locationId,
// 				first_name: values.firstName,
// 				last_name: values.lastName,
// 				email: values.email,
// 				name: values.name, // Assuming name is derived from firstName for simplicity
// 				password: values.password,
// 			});
// 		},
// 	});

// 	React.useEffect(() => {
// 		if (option) {
// 			formik.setValues({
// 				locationId: option.id,
// 				firstName: option.firstName,
// 				lastName: option.lastName,
// 				email: option.email,
// 				password: option?.password || "",
// 				name: option?.name || "",
// 			});
// 			setOpen(true);
// 		}
// 	}, [option]);

// 	const handleClose = () => {
// 		setOpen(false);
// 		formik.resetForm();
// 	};

// 	return (
// 		<div>
// 			<Modal
// 				aria-labelledby="modal-title"
// 				aria-describedby="modal-description"
// 				open={open}
// 				onClose={handleClose}
// 				closeAfterTransition
// 				BackdropComponent={Backdrop}
// 				BackdropProps={{
// 					timeout: 500,
// 				}}
// 			>
// 				<Fade in={open}>
// 					{/* sx=
// 					{{
// 						"& .MuiPaper-root": {
// 							borderRadius: 2,
// 							boxShadow: 24,
// 							p: 2,
// 							maxWidth: 400,
// 							transition: "transform 0.3s ease, box-shadow 0.3s ease",
// 							"&:hover": {
// 								transform: "scale(1.02)",
// 								boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)",
// 							},
// 						},
// 					}} */}
// 					<Box sx={modalStyle}>
// 						<Typography
// 							id="modal-title"
// 							variant="h6"
// 							component="h2"
// 							sx={{ mb: 2, textAlign: "center" }}
// 						>
// 							Add Sub Account
// 						</Typography>
// 						<form onSubmit={formik.handleSubmit}>
// 							<Stack spacing={3}>
// 								<TextField
// 									id="locationId"
// 									label="Location ID"
// 									name="locationId"
// 									variant="outlined"
// 									size="small"
// 									value={formik.values.locationId}
// 									disabled
// 									fullWidth
// 								/>
// 								<Stack direction="row" spacing={2}>
// 									<TextField
// 										id="firstName"
// 										label="First Name"
// 										name="firstName"
// 										variant="outlined"
// 										size="small"
// 										value={formik.values.firstName}
// 										onChange={formik.handleChange}
// 										error={
// 											formik.touched.firstName &&
// 											Boolean(formik.errors.firstName)
// 										}
// 										helperText={
// 											formik.touched.firstName && formik.errors.firstName
// 										}
// 										fullWidth
// 									/>
// 									<TextField
// 										id="lastName"
// 										label="Last Name"
// 										name="lastName"
// 										variant="outlined"
// 										size="small"
// 										value={formik.values.lastName}
// 										onChange={formik.handleChange}
// 										error={
// 											formik.touched.lastName && Boolean(formik.errors.lastName)
// 										}
// 										helperText={
// 											formik.touched.lastName && formik.errors.lastName
// 										}
// 										fullWidth
// 									/>
// 								</Stack>
// 								<TextField
// 									id="email"
// 									label="Email"
// 									name="email"
// 									variant="outlined"
// 									size="small"
// 									value={formik.values.email}
// 									onChange={formik.handleChange}
// 									error={formik.touched.email && Boolean(formik.errors.email)}
// 									helperText={formik.touched.email && formik.errors.email}
// 									fullWidth
// 								/>
// 								<Tooltip title="Ensure this is a secure password" arrow>
// 									<TextField
// 										id="password"
// 										label="Password"
// 										name="password"
// 										type="password"
// 										variant="outlined"
// 										size="small"
// 										value={formik.values.password}
// 										onChange={formik.handleChange}
// 										error={
// 											formik.touched.password && Boolean(formik.errors.password)
// 										}
// 										helperText={
// 											formik.touched.password && formik.errors.password
// 										}
// 										fullWidth
// 									/>
// 								</Tooltip>
// 								<Box
// 									sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
// 								>
// 									<Button
// 										onClick={handleClose}
// 										variant="outlined"
// 										color="error"
// 										disabled={mutation.isLoading}
// 										sx={{ minWidth: 120 }}
// 									>
// 										Cancel
// 									</Button>
// 									<Button
// 										type="submit"
// 										variant="contained"
// 										// color={Colors.primary}

// 										disabled={mutation.isLoading}
// 										sx={{
// 											minWidth: 120,
// 											bgcolor: Colors.primary,
// 											"&:hover": {
// 												bgcolor: Colors.primaryDark, // Change to your desired hover color
// 											},
// 										}}
// 										startIcon={
// 											mutation.isLoading && <CircularProgress size={20} />
// 										}
// 									>
// 										{mutation.isLoading ? "Adding..." : "Add"}
// 									</Button>
// 								</Box>
// 							</Stack>
// 						</form>
// 					</Box>
// 				</Fade>
// 			</Modal>

// 			{/* Loading state on backdrop */}
// 			<Backdrop
// 				sx={{
// 					color: "#fff",
// 					zIndex: (theme) => theme.zIndex.drawer + 1,
// 				}}
// 				open={mutation.isLoading}
// 			>
// 				<CircularProgress color="inherit" />
// 			</Backdrop>
// 		</div>
// 		// <div>
// 		// 	<Modal
// 		// 		aria-labelledby="transition-modal-title"
// 		// 		aria-describedby="transition-modal-description"
// 		// 		open={open}
// 		// 		onClose={handleClose}
// 		// 		closeAfterTransition
// 		// 		slots={{ backdrop: Backdrop }}
// 		// 		slotProps={{
// 		// 			backdrop: {
// 		// 				timeout: 500,
// 		// 			},
// 		// 		}}
// 		// 	>
// 		// 		<Fade in={open}>
// 		// 			<Box sx={style}>
// 		// 				<Typography
// 		// 					id="transition-modal-title"
// 		// 					variant="h6"
// 		// 					component="h2"
// 		// 					mb={2}
// 		// 				>
// 		// 					Add Sub Account
// 		// 				</Typography>
// 		// 				<form onSubmit={formik.handleSubmit}>
// 		// 					<Stack direction="column" spacing={2}>
// 		// 						<Box sx={{ justifyContent: "flex-start", display: "flex" }}>
// 		// 							<TextField
// 		// 								id="locationId"
// 		// 								label="Location ID"
// 		// 								name="locationId"
// 		// 								variant="outlined"
// 		// 								size="small"
// 		// 								sx={{
// 		// 									width: "40%",
// 		// 									display: "block",
// 		// 								}}
// 		// 								value={formik.values.locationId}
// 		// 								onChange={formik.handleChange}
// 		// 								disabled
// 		// 							/>
// 		// 						</Box>
// 		// 						<Box sx={{ display: "flex", gap: "5%" }}>
// 		// 							<TextField
// 		// 								id="firstName"
// 		// 								label="First Name"
// 		// 								name="firstName"
// 		// 								variant="outlined"
// 		// 								size="small"
// 		// 								sx={{
// 		// 									width: "100%",
// 		// 								}}
// 		// 								value={formik.values.firstName}
// 		// 								onChange={formik.handleChange}
// 		// 								error={
// 		// 									formik.touched.firstName &&
// 		// 									Boolean(formik.errors.firstName)
// 		// 								}
// 		// 								helperText={
// 		// 									formik.touched.firstName && formik.errors.firstName
// 		// 								}
// 		// 							/>

// 		// 							<TextField
// 		// 								id="lastName"
// 		// 								label="Last Name"
// 		// 								name="lastName"
// 		// 								variant="outlined"
// 		// 								size="small"
// 		// 								sx={{
// 		// 									width: "100%",
// 		// 								}}
// 		// 								value={formik.values.lastName}
// 		// 								onChange={formik.handleChange}
// 		// 								error={
// 		// 									formik.touched.lastName && Boolean(formik.errors.lastName)
// 		// 								}
// 		// 								helperText={
// 		// 									formik.touched.lastName && formik.errors.lastName
// 		// 								}
// 		// 							/>
// 		// 						</Box>

// 		// 						<Stack direction="column" spacing={2}>
// 		// 							<TextField
// 		// 								id="email"
// 		// 								label="Email"
// 		// 								name="email"
// 		// 								size="small"
// 		// 								variant="outlined"
// 		// 								width="100%"
// 		// 								value={formik.values.email}
// 		// 								onChange={formik.handleChange}
// 		// 								error={formik.touched.email && Boolean(formik.errors.email)}
// 		// 								helperText={formik.touched.email && formik.errors.email}
// 		// 							/>

// 		// 							<TextField
// 		// 								id="password"
// 		// 								label="Password"
// 		// 								name="password"
// 		// 								type="password"
// 		// 								variant="outlined"
// 		// 								size="small"
// 		// 								width="100%"
// 		// 								value={formik.values.password}
// 		// 								onChange={formik.handleChange}
// 		// 								error={
// 		// 									formik.touched.password && Boolean(formik.errors.password)
// 		// 								}
// 		// 								helperText={
// 		// 									formik.touched.password && formik.errors.password
// 		// 								}
// 		// 							/>
// 		// 						</Stack>
// 		// 						<Box
// 		// 							sx={{
// 		// 								justifyContent: "flex-end",
// 		// 								display: "flex",
// 		// 								gap: "2%",
// 		// 							}}
// 		// 						>
// 		// 							<Button
// 		// 								sx={{ width: "20%" }}
// 		// 								onClick={handleClose}
// 		// 								variant="outlined"
// 		// 								color="error"
// 		// 							>
// 		// 								Cancel
// 		// 							</Button>
// 		// 							<Button
// 		// 								sx={{ width: "20%" }}
// 		// 								type="submit"
// 		// 								variant="contained"
// 		// 								disabled={mutation.isLoading}
// 		// 							>
// 		// 								Add
// 		// 							</Button>
// 		// 						</Box>
// 		// 					</Stack>
// 		// 				</form>
// 		// 			</Box>
// 		// 		</Fade>
// 		// 	</Modal>
// 		// 	<Backdrop
// 		// 		sx={{
// 		// 			color: "#fff",
// 		// 			zIndex: (theme) => theme.zIndex.drawer + 1,
// 		// 		}}
// 		// 		open={mutation.isLoading}
// 		// 	>
// 		// 		<CircularProgress color="inherit" />
// 		// 	</Backdrop>
// 		// </div>
// 	);
// }

// function showToast(status) {
// 	const response = {
// 		400: { flag: "info", message: "Location Already Exists" },
// 		201: { flag: "success", message: "Location Added Successfully" },
// 		500: { flag: "error", message: "Failed to add location" },
// 	};

// 	const result = response[status];

// 	if (result) {
// 		toast[result.flag](result.message, {
// 			position: "bottom-left",
// 			autoClose: 1500,
// 			hideProgressBar: true,
// 			closeOnClick: true,
// 			pauseOnHover: true,
// 			draggable: true,
// 			progress: undefined,
// 			theme: "colored",
// 		});
// 	}
// }





import * as React from "react";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { createResource } from "../hooks/HttpsRequestHooks";
import { useFormik } from "formik";
import Colors from "../@colors/Colors";
import * as Yup from "yup";
import {
	Backdrop,
	Box,
	Modal,
	Fade,
	Button,
	Typography,
	CircularProgress,
	Stack,
	TextField,
	Tooltip,
} from "@mui/material";
import { useSpring, animated } from "@react-spring/web";

// Animation for Fade component
const AnimatedFade = React.forwardRef(function Fade(props, ref) {
	const { children, in: open, onClick, onEnter, onExited, ...other } = props;
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter(null, true);
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited(null, true);
			}
		},
	});
	return (
		<animated.div ref={ref} style={style} {...other}>
			{React.cloneElement(children, { onClick })}
		</animated.div>
	);
});

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 600,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	borderRadius: 2,
	textAlign: "center",
	transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
	"&:hover": {
		transform: "translate(-50%, -50%) scale(1.02)",
		boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.2)",
	},
};

// Validation schema with Yup
const validationSchema = Yup.object({
	locationId: Yup.string(),
	firstName: Yup.string().required("First Name is required"),
	lastName: Yup.string().required("Last Name is required"),
	name: Yup.string().required("Name is required"),
	email: Yup.string()
		.email("Invalid email format")
		.required("Email is required"),
	password: Yup.string().required("Password is required"),
});

export default function AddLocation({ option, onSuccessCallback }) {
	const [open, setOpen] = React.useState(false);

	const mutation = useMutation((data) => createResource(`/locations/`, data), {
		onSuccess: () => {
			showToast(201);
			handleClose();
			onSuccessCallback(true); // Notify parent of success
		},
		onError: (error) => {
			showToast(error.status || 500);
		},
	});

	const formik = useFormik({
		initialValues: {
			locationId: option?.id || "",
			firstName: option?.firstName || "",
			lastName: option?.lastName || "",
			name: option?.name || "",
			email: option?.email || "",
			password: option?.password || "",
		},
		validationSchema,
		onSubmit: (values) => {
			mutation.mutate({
				locationId: values.locationId,
				first_name: values.firstName,
				last_name: values.lastName,
				email: values.email,
				name: values.name, // Assuming name is derived from firstName for simplicity
				password: values.password,
			});
		},
	});

	React.useEffect(() => {
		if (option) {
			formik.setValues({
				locationId: option.id,
				firstName: option.firstName,
				lastName: option.lastName,
				email: option.email,
				password: option?.password || "",
				name: option?.name || "",
			});
			setOpen(true);
		}
	}, [option]);

	const handleClose = () => {
		setOpen(false);
		formik.resetForm();
	};

	return (
		<div>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<AnimatedFade in={open}>
					<Box sx={modalStyle}>
						<Typography
							id="modal-title"
							variant="h6"
							component="h2"
							sx={{ mb: 2, textAlign: "center" }}
						>
							Add Sub Account
						</Typography>
						<form onSubmit={formik.handleSubmit}>
							<Stack spacing={3}>
								<TextField
									id="locationId"
									label="Location ID"
									name="locationId"
									variant="outlined"
									size="small"
									value={formik.values.locationId}
									disabled
									fullWidth
								/>
								<Stack direction="row" spacing={2}>
									<TextField
										id="firstName"
										label="First Name"
										name="firstName"
										variant="outlined"
										size="small"
										value={formik.values.firstName}
										onChange={formik.handleChange}
										error={
											formik.touched.firstName &&
											Boolean(formik.errors.firstName)
										}
										helperText={
											formik.touched.firstName && formik.errors.firstName
										}
										fullWidth
									/>
									<TextField
										id="lastName"
										label="Last Name"
										name="lastName"
										variant="outlined"
										size="small"
										value={formik.values.lastName}
										onChange={formik.handleChange}
										error={
											formik.touched.lastName && Boolean(formik.errors.lastName)
										}
										helperText={
											formik.touched.lastName && formik.errors.lastName
										}
										fullWidth
									/>
								</Stack>
								<TextField
									id="email"
									label="Email"
									name="email"
									variant="outlined"
									size="small"
									value={formik.values.email}
									onChange={formik.handleChange}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={formik.touched.email && formik.errors.email}
									fullWidth
								/>
								<Tooltip title="Ensure this is a secure password" arrow>
									<TextField
										id="password"
										label="Password"
										name="password"
										type="password"
										variant="outlined"
										size="small"
										value={formik.values.password}
										onChange={formik.handleChange}
										error={
											formik.touched.password && Boolean(formik.errors.password)
										}
										helperText={
											formik.touched.password && formik.errors.password
										}
										fullWidth
									/>
								</Tooltip>
								<Box
									sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
								>
									<Button
										onClick={handleClose}
										variant="outlined"
										color="error"
										disabled={mutation.isLoading}
										sx={{ minWidth: 120 }}
									>
										Cancel
									</Button>
									<Button
										type="submit"
										variant="contained"
										disabled={mutation.isLoading}
										sx={{
											minWidth: 120,
											bgcolor: Colors.primary,
											"&:hover": {
												bgcolor: Colors.primaryDark, // Change to your desired hover color
											},
										}}
										startIcon={
											mutation.isLoading && <CircularProgress size={20} />
										}
									>
										{mutation.isLoading ? "Adding..." : "Add"}
									</Button>
								</Box>
							</Stack>
						</form>
					</Box>
				</AnimatedFade>
			</Modal>

			{/* Loading state on backdrop */}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={mutation.isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}

function showToast(status) {
	const response = {
		400: { flag: "info", message: "Location Already Exists" },
		201: { flag: "success", message: "Location Added Successfully" },
		500: { flag: "error", message: "Failed to add location" },
	};

	const result = response[status];

	if (result) {
		toast[result.flag](result.message, {
			position: "bottom-left",
			autoClose: 1500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "colored",
		});
	}
}
