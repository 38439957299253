import React from "react";
import { Button, CircularProgress } from "@mui/material";
import Colors from "../@colors/Colors";

const CustomButton = ({ text, type = "button", ...props }) => {
	const style = {
		backgroundColor: Colors.primary,
		color: Colors.neutral,
		textTransform: "none",
	};
	const style_ = {
		color: Colors.neutral,
	}
	const mergedStyles = { ...style, ...props.style };
	return (
		<Button style={mergedStyles} type={type}>
			{props.loading ? <CircularProgress size={24} color="inherit" /> : text}
		</Button>
	);
};

export default CustomButton;
