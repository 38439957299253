// import React, { useEffect, useState } from "react";
// import { Button, Container, Typography } from "@mui/material";
// import Colors from "../@colors/Colors";
// import { usePost } from "../hooks/HttpsRequestHooks";
// import baseUrl from "../@api/BaseUrls";
// import useSWR from "swr";
// import { fetcher } from "../hooks/HttpsRequestHooks";
// const Authentication = () => {
// 	const [shouldFetch, setShouldFetch] = useState(true);
// 	const [data, setData] = useState(null);
// 	const {
// 		data: crm_connection_data,
// 		isLoading,
// 		error: crmConnectionError,
// 	} = useSWR(`${baseUrl}/is-connected`, fetcher);
// 	// const [expiryTime, setExpiryTime] = useState(
// 	// 	crm_connection_data
// 	// 		? crm_connection_data
// 	// 		: localStorage.getItem("expiry_time")
// 	// 		? parseInt(localStorage.getItem("expiry_time"))
// 	// 		: 0
// 	// );
// 	const { data: response, error, loading, postData } = usePost();

// 	function handleClick() {
// 		const json_data = {
// 			dummy: "",
// 		};
// 		const header = {
// 			"Content-Type": "application/json",
// 		};
// 		try {
// 			const res = postData(`${baseUrl}/auth`, json_data, header);

// 			setData(res.data);
// 		} catch (error) {
// 			console.log(error);
// 		}
// 	}
// 	useEffect(() => {
// 		if (response) {
// 			window.location.href = response;
// 		}
// 	}, [response]);

// 	// useEffect(() => {
// 	// 	if (crm_connection_data) {
// 	// 		localStorage.setItem("expiry_time", crm_connection_data.expire);
// 	// 	}
// 	// }, [crm_connection_data]);
// 	const buttonStatus = (expire_time) => {
// 		// let expire_time = localStorage.getItem("expiry_time");
// 		const attr =
// 			parseInt(expire_time) > parseInt(Date.now())
// 				? { color: "red", text: "Connected" }
// 				: { color: Colors.primary, text: "Connect" };
// 		return attr;
// 	};

// 	return (
// 		<Container
// 			sx={{
// 				height: "40vh",
// 				display: "flex",
// 				flexDirection: "column",
// 				justifyContent: "center",
// 				alignItems: "center",
// 			}}
// 		>
// 			<Typography fontSize="1.5rem" variant="h4" gutterBottom>
// 				Authenticate
// 			</Typography>
// 			<Button
// 				onClick={handleClick}
// 				variant="contained"
// 				style={{
// 					textTransform: "none",
// 					width: "60%",
// 					color: Colors.neutral,
// 					backgroundColor: buttonStatus(crm_connection_data).color,
// 				}}
// 			>
// 				{buttonStatus().text}
// 			</Button>
// 		</Container>
// 	);
// };

// export default Authentication;

import React, { useEffect, useState } from "react";
import { Button, Container, Typography } from "@mui/material";
import Colors from "../@colors/Colors";
import { usePost } from "../hooks/HttpsRequestHooks";
import baseUrl from "../@api/BaseUrls";
import useSWR from "swr";
import { fetcher } from "../hooks/HttpsRequestHooks";

const Authentication = () => {
	const {
		data: crmConnectionData,
		isLoading,
		error,
	} = useSWR(`${baseUrl}/is-connected`, fetcher);
	const { data: response, loading, postData } = usePost();

	function handleClick() {
		const json_data = {
			dummy: "",
		};
		const header = {
			"Content-Type": "application/json",
		};
		try {
			postData(`${baseUrl}/auth`, json_data, header);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		if (response) {
			window.location.href = response;
		}
	}, [response]);

	const getButtonStatus = () => {
		const isConnected =
			crmConnectionData?.expire &&
			parseInt(crmConnectionData.expire) > Date.now();
		return {
			color: isConnected ? "red" : Colors.primary,
			text: isConnected ? "Connected" : "Connect",
		};
	};

	const buttonStatus = getButtonStatus();
	const isButtonDisabled = isLoading || buttonStatus.text === "Connected";

	return (
		<Container
			sx={{
				height: "40vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Typography fontSize="1.5rem" variant="h4" gutterBottom>
				Authenticate
			</Typography>
			<Button
				onClick={handleClick}
				variant="contained"
				style={{
					textTransform: "none",
					width: "60%",
					color: Colors.neutral,
					backgroundColor: buttonStatus.color,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
				disabled={isButtonDisabled}
			>
				{isLoading ? (
					<span style={{ display: "flex", alignItems: "center" }}>
						<span className="dot"></span>
						<span className="dot"></span>
						<span className="dot"></span>
					</span>
				) : (
					buttonStatus.text
				)}
			</Button>
			<style>
				{`
					.dot {
						width: 8px;
						height: 8px;
						margin-top: 10px;
						margin-bottom: 10px;
						border-radius: 50%;
						background-color: ${Colors.neutral};
						animation: blink 1.4s infinite both;
					}

					.dot:nth-child(2) {
						animation-delay: 0.2s;
					}

					.dot:nth-child(3) {
						animation-delay: 0.4s;
					}

					@keyframes blink {
						0% { opacity: 0; }
						50% { opacity: 1; }
						100% { opacity: 0; }
					}
				`}
			</style>
		</Container>
	);
};

export default Authentication;
