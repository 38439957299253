import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import IconButton from "@mui/material/IconButton";
import { useDelete } from "../../hooks/HttpsRequestHooks";
import baseUrl from "../../@api/BaseUrls";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import WarningDialog from "./WarningDialog";

const Fade = React.forwardRef(function Fade(props, ref) {
	const {
		children,
		in: open,
		onClick,
		onEnter,
		onExited,
		ownerState,
		...other
	} = props;
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter(null, true);
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited(null, true);
			}
		},
	});
	return (
		<animated.div ref={ref} style={style} {...other}>
			{React.cloneElement(children, { onClick })}
		</animated.div>
	);
});

Fade.propTypes = {
	children: PropTypes.element.isRequired,
	in: PropTypes.bool,
	onClick: PropTypes.any,
	onEnter: PropTypes.func,
	onExited: PropTypes.func,
	ownerState: PropTypes.any,
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	textAlign: "center",
};

export default function DeleteConfirmationModal({
	service_sid,
	locationId,
	onSuccessCallback,
}) {
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const { data, error, deleteData } = useDelete();

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleDelete = async () => {
		setLoading(true);
		try {
			await deleteData(`${baseUrl}/locations/${locationId}`);
			onSuccessCallback(true);
		} catch (e) {
			console.error(e);
		}
		setLoading(false);
		setOpen(false);
	};

	React.useEffect(() => {
		if (data) {
			toast.success("Location deleted successfully", {
				position: "bottom-left",
				autoClose: 1500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		}
		if (error) {
			toast.error("Failed to delete location", {
				position: "bottom-left",
				autoClose: 1500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		}
	}, [data, error]);

	return (
		<div>
			<IconButton onClick={handleOpen} aria-label="delete">
				<DeleteIcon />
			</IconButton>
			{service_sid ? (
				<WarningDialog open={open} onClose={handleClose} />
			) : (
				<Modal
					aria-labelledby="confirmation-dialog-title"
					aria-describedby="confirmation-dialog-description"
					open={open}
					onClose={handleClose}
					closeAfterTransition
					slots={{ backdrop: Backdrop }}
					slotProps={{ backdrop: { TransitionComponent: Fade } }}
				>
					<Fade in={open}>
						<Box
							sx={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								width: 400,
								bgcolor: "background.paper",
								boxShadow: 24,
								p: 4,
								borderRadius: 2,
								textAlign: "center",
								transition: "transform 0.3s ease-in-out",
								"&:hover": {
									transform: "translate(-50%, -50%) scale(1.02)",
									boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.2)",
								},
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									color: "text.primary",
									fontWeight: "bold",
									fontSize: "18px",
									paddingBottom: "10px",
									transition: "color 0.3s ease-in-out",
								}}
							>
								<ErrorOutlineIcon color="error" sx={{ fontSize: 28, mr: 1 }} />
								Confirm Delete
							</Box>
							<Typography
								id="confirmation-dialog-description"
								sx={{ mb: 3, color: "text.secondary", textAlign: "start" }}
							>
								Are you sure you want to delete this location? This action
								cannot be undone.
							</Typography>
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									gap: "10px",
									// paddingTop: "10px",
								}}
							>
								<Button
									variant="outlined"
									onClick={handleClose}
									sx={{
										textTransform: "none",
										color: "text.primary",
										borderColor: "divider",
										"&:hover": {
											backgroundColor: "action.hover",
										},
									}}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									color="error"
									onClick={handleDelete}
									disabled={loading}
									sx={{
										textTransform: "none",
										"&:hover": {
											backgroundColor: "error.dark",
										},
									}}
								>
									{loading ? (
										<CircularProgress size={24} color="inherit" />
									) : (
										"Confirm"
									)}
								</Button>
							</Box>
						</Box>
					</Fade>
				</Modal>
			)}
		</div>
	);
}

DeleteConfirmationModal.propTypes = {
	locationId: PropTypes.string.isRequired,
	onSuccessCallback: PropTypes.func.isRequired,
};
