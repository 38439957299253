import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import { Typography } from "@mui/material";

export default function WarningDialog({ open, onClose }) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="warning-dialog-title"
			aria-describedby="warning-dialog-description"
			sx={{
				"& .MuiPaper-root": {
					borderRadius: 2,
					boxShadow: 24,
					p: 2,
					maxWidth: 400,
					transition: "transform 0.3s ease, box-shadow 0.3s ease",
					"&:hover": {
						transform: "scale(1.02)",
						boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)",
					},
				},
			}}
		>
			<DialogTitle
				id="warning-dialog-title"
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					// color: "warning.main",
					fontWeight: 600,
					// mb: ,
					// fontSize: "1.25rem",
				}}
			>
				<WarningIcon color="warning" sx={{ fontSize: 40, mb: 2 }} />
				<Typography
					id="confirmation-dialog-title"
					variant="h6"
					component="h2"
					sx={{ fontWeight: 500 }}
				>
					Warning
				</Typography>
			</DialogTitle>
			<DialogContent>
				<DialogContentText
					id="warning-dialog-description"
					sx={{
						color: "text.secondary",
						fontSize: "0.875rem",
						textAlign: "center",
					}}
				>
					To delete the user, you first need to disable the associated service.
					Please disable the service before proceeding with the deletion.
				</DialogContentText>
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: "center",
					mb: 2,
				}}
			>
				<Button
					sx={{
						textTransform: "none",
						fontWeight: 500,
						color: "warning.main",
						borderColor: "warning.main",
						"&:hover": {
							backgroundColor: "warning.light",
							borderColor: "warning.main",
							color: "common.white",
						},
						width: "100px",
					}}

					variant="outlined"
					onClick={onClose}
					
				>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
}
