const Colors = {
	primary: "#8CC642", //"#71993d" 363636
	primaryDark: "#71993d",
	secondary: "#0A0A0A", //"#428CC6"
	tertiary: "#C6428C",
	neutral: "#fff",
	off: "rgba(250, 250, 250, 1)",
};

export default Colors;
