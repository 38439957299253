import Cookies from "js-cookie";

// Function to set the token in a secure cookie
const setToken = (tokens) => {
	Cookies.set("houzoo_auth_token", tokens.access_token, {
		secure: true,
		sameSite: "None",
		expires: 1,
	});
	Cookies.set("houzoo_refresh_token", tokens.refresh_token, {
		secure: true,
		sameSite: "None",
		expires: 1,
	});
};

// Function to get the token from the secure cookie
const getToken = () => {
	return Cookies.get("houzoo_auth_token");
};
const getTokenRefresh = () => {
	return Cookies.get("houzoo_refresh_token");
};

const setAccessToken = (token) => {
	Cookies.set("houzoo_auth_token", token, {
		// secure: true,
		sameSite: "strict",
		expires: 0.5,
	});
};

const removeAccessToken = () => {
	Cookies.remove("houzoo_auth_token");
};
// Function to remove the token from the secure cookie
const removeToken = () => {
	Cookies.remove("houzoo_auth_token");
	Cookies.remove("houzoo_refresh_token");
};

export {
	setToken,
	getToken,
	removeToken,
	setAccessToken,
	getTokenRefresh,
	removeAccessToken,
};
