// import * as React from "react";
// import Box from "@mui/material/Box";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
// import baseUrl from "../../@api/BaseUrls";
// import { fetcher } from "../../hooks/HttpsRequestHooks";
// import useSWR from "swr";
// import Colors from "../../@colors/Colors";
// import { Button } from "@mui/material";

// // TabPanel component for rendering content
// function TabPanel(props) {
// 	const { children, value, index, ...other } = props;

// 	return (
// 		<div
// 			role="tabpanel"
// 			hidden={value !== index}
// 			id={`tabpanel-${index}`}
// 			aria-labelledby={`tab-${index}`}
// 			{...other}
// 		>
// 			{value === index && (
// 				<Box sx={{ p: 3 }}>
// 					<Typography component="div">{children}</Typography>
// 				</Box>
// 			)}
// 		</div>
// 	);
// }

// // Main component
// export default function AddiontalComments({ id }) {
// 	const [value, setValue] = React.useState(0);

// 	// using SWR to fetch data
// 	const { data, error, isLoading } = useSWR(
// 		id ? `${baseUrl}/addional-comments/${id}` : null,
// 		fetcher
// 	);

// 	const handleChange = (event, newValue) => {
// 		setValue(newValue);
// 	};

// 	// if (isLoading) return <WavingDotsLoader />;
// 	// if (error) return <div>Error loading data</div>;

// 	// Helper function to render HTML content safely
// 	const createMarkup = (html) => {
// 		return { __html: html };
// 	};

// 	return (
// 		<Box sx={{ width: "100%", mt: 4 }}>
// 			<Tabs
// 				sx={{ bgcolor: Colors.off }}
// 				value={value}
// 				onChange={handleChange}
// 				centered

// 			>
// 				<Tab sx={{ textTransform: "none" }} label="Word for Word Match" />
// 				<Tab
// 					sx={{ textTransform: "none" }}
// 					label="Word for Word Change Order"
// 				/>
// 				<Tab sx={{ textTransform: "none" }} label="Synonym Usage" />
// 				<Tab sx={{ textTransform: "none" }} label="Appointment Setting" />
// 				<Tab><Button>Some</Button></Tab>
// 			</Tabs>

// 			{/* Tab panels for each tab */}
// 			<TabPanel value={value} index={0}>
// 				{/* Word for Word Match content as HTML */}
// 				{data ? (
// 					<div
// 						dangerouslySetInnerHTML={createMarkup(
// 							data.data.wordforwordmatch || "No data available"
// 						)}
// 					/>
// 				) : (
// 					<DataNotFound />
// 				)}
// 			</TabPanel>

// 			<TabPanel value={value} index={1}>
// 				{/* Word for Word Change Order content as HTML */}
// 				{data ? (
// 					<div
// 						dangerouslySetInnerHTML={createMarkup(
// 							data.data.changedorder || "No data available"
// 						)}
// 					/>
// 				) : (
// 					<DataNotFound />
// 				)}
// 			</TabPanel>

// 			<TabPanel value={value} index={2}>
// 				{/* Synonym Usage content as HTML */}
// 				{data ? (
// 					<div
// 						dangerouslySetInnerHTML={createMarkup(
// 							data.data.synonymusage || "No data available"
// 						)}
// 					/>
// 				) : (
// 					<DataNotFound />
// 				)}
// 			</TabPanel>

// 			<TabPanel value={value} index={3}>
// 				{/* Appointment Setting content as HTML */}
// 				{data ? (
// 					<div
// 						dangerouslySetInnerHTML={createMarkup(
// 							data.data.appointmentsetting || "No data available"
// 						)}
// 					/>
// 				) : (
// 					<DataNotFound />
// 				)}
// 			</TabPanel>
// 			{isLoading && <WavingDotsLoader />}
// 		</Box>
// 	);
// }

// const WavingDotsLoader = () => {
// 	const loaderStyle = {
// 		display: "flex",
// 		alignItems: "center",
// 		justifyContent: "center",
// 		gap: "4px",
// 	};

// 	const dotStyle = {
// 		width: "8px",
// 		height: "8px",
// 		backgroundColor: "#333",
// 		borderRadius: "50%",
// 		animation: "wave 1.4s infinite ease-in-out",
// 	};

// 	const dotAnimationStyles = `
//     @keyframes wave {
//       0%, 100% {
//         transform: translateY(0);
//       }
//       50% {
//         transform: translateY(-12px);
//       }
//     }
//   `;

// 	return (
// 		<div>
// 			<style>{dotAnimationStyles}</style>
// 			<div style={loaderStyle}>
// 				<div style={{ ...dotStyle, animationDelay: "-0.32s" }} />
// 				<div style={{ ...dotStyle, animationDelay: "-0.16s" }} />
// 				<div style={dotStyle} />
// 			</div>
// 		</div>
// 	);
// };

// const DataNotFound = () => {
// 	const containerStyle = {
// 		display: "flex",
// 		flexDirection: "column",
// 		alignItems: "center",
// 		justifyContent: "center",
// 		height: "100vh",
// 	};

// 	const messageStyle = {
// 		fontSize: "18px",
// 		color: "#666",
// 		textAlign: "center",
// 		animation: "fadeIn 1.5s ease-in-out infinite",
// 	};

// 	const animationStyles = `
//     @keyframes fadeIn {
//       0% {
//         opacity: 0;
//         transform: translateY(20px);
//       }
//       50% {
//         opacity: 0.5;
//         transform: translateY(0);
//       }
//       100% {
//         opacity: 1;
//         transform: translateY(-20px);
//       }
//     }
//   `;

// 	return (
// 		<div style={containerStyle}>
// 			<style>{animationStyles}</style>
// 			<div style={messageStyle}>Data Not Found</div>
// 		</div>
// 	);
// };

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import baseUrl from "../../@api/BaseUrls";
import { fetcher } from "../../hooks/HttpsRequestHooks";
import useSWR from "swr";
import Colors from "../../@colors/Colors";

// TabPanel component for rendering content
function TabPanel(props) {
	const { children, value, ...other } = props;

	return (
		<div role="tabpanel" hidden={value === false} {...other}>
			{value && (
				<Box sx={{ p: 3 }}>
					<Typography component="div">{children}</Typography>
				</Box>
			)}
		</div>
	);
}

// Main component
export default function AddionalComments({ id }) {
	const [activeButton, setActiveButton] = React.useState(0);

	// using SWR to fetch data
	const { data, error, isLoading } = useSWR(
		id ? `${baseUrl}/addional-comments/${id}` : null,
		fetcher
	);

	// Helper function to render HTML content safely
	const createMarkup = (html) => {
		return { __html: html };
	};

	const handleButtonClick = (index) => {
		setActiveButton(index);
	};

	const buttonStyle = {
		borderRadius: "0",
		height: "60px",
		color: "white",
		fontSize: "16px",
		fontWeight: "bolder",
		textAlign: "left",
		padding: "10px 30px",
	};

	return (
		<Box sx={{ width: "100%", mt: 4 }} id="addional-comments">
			<Box sx={{ display: "flex", justifyContent: "left", mb: 2, ml: 5 }}>
				{[0, 1, 2, 3].map((index) => (
					<Button
						key={index}
						style={buttonStyle}
						sx={{
							"&:hover": {
								backgroundColor: Colors.primary,
								"& .content": {
									color: `${Colors.off} !important`,
								},
							},
							backgroundColor:
								activeButton === index ? Colors.primary : "transparent", // Active button background
							"& .content": {
								color:
									activeButton === index
										? `${Colors.off} !important`
										: Colors.secondary, // Change text color based on active button
							},
						}}
						onClick={() => handleButtonClick(index)}
					>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<span
								style={{
									letterSpacing: "1px",
									lineHeight: 1,
									margin: 0,
									color:
										activeButton === index ? `${Colors.off}` : Colors.secondary,
								}}
								className="content"
							>
								{index === 0 && "Word for Word"}
								{index === 1 && "Word for Word"}
								{index === 2 && "Synonym"}
								{index === 3 && "Appointment"}
							</span>
							<br />
							<span
								style={{
									fontSize: "12px",
									fontWeight: "bold",
									color:
										activeButton === index ? `${Colors.off}` : Colors.primary,
								}}
								className="content"
							>
								{index === 0 && "Match"}
								{index === 1 && "Change Order"}
								{index === 2 && "Usage"}
								{index === 3 && "Setting"}
							</span>
						</Box>
					</Button>
				))}
			</Box>

			{/* Button panels for each button */}
			<TabPanel value={activeButton === 0}>
				{data ? (
					<div
						dangerouslySetInnerHTML={createMarkup(
							data.data.wordforwordmatch || "No data available"
						)}
					/>
				) : (
					<DataNotFound />
				)}
			</TabPanel>

			<TabPanel value={activeButton === 1}>
				{data ? (
					<div
						dangerouslySetInnerHTML={createMarkup(
							data.data.changedorder || "No data available"
						)}
					/>
				) : (
					<DataNotFound />
				)}
			</TabPanel>

			<TabPanel value={activeButton === 2}>
				{data ? (
					<div
						dangerouslySetInnerHTML={createMarkup(
							data.data.synonymusage || "No data available"
						)}
					/>
				) : (
					<DataNotFound />
				)}
			</TabPanel>

			<TabPanel value={activeButton === 3}>
				{data ? (
					<div
						dangerouslySetInnerHTML={createMarkup(
							data.data.appointmentsetting || "No data available"
						)}
					/>
				) : (
					<DataNotFound />
				)}
			</TabPanel>
			{isLoading && <WavingDotsLoader />}
		</Box>
	);
}

const WavingDotsLoader = () => {
	const loaderStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: "4px",
	};

	const dotStyle = {
		width: "8px",
		height: "8px",
		backgroundColor: "#333",
		borderRadius: "50%",
		animation: "wave 1.4s infinite ease-in-out",
	};

	const dotAnimationStyles = `
    @keyframes wave {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-12px);
      }
    }
  `;

	return (
		<div>
			<style>{dotAnimationStyles}</style>
			<div style={loaderStyle}>
				<div style={{ ...dotStyle, animationDelay: "-0.32s" }} />
				<div style={{ ...dotStyle, animationDelay: "-0.16s" }} />
				<div style={dotStyle} />
			</div>
		</div>
	);
};

const DataNotFound = () => {
	const containerStyle = {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: "100vh",
	};

	const messageStyle = {
		fontSize: "18px",
		color: "#666",
		textAlign: "center",
		animation: "fadeIn 1.5s ease-in-out infinite",
	};

	const animationStyles = `
    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(20px);
      }
      50% {
        opacity: 0.5;
        transform: translateY(0);
      }
      100% {
        opacity: 1;
        transform: translateY(-20px);
      }
    }
  `;

	return (
		<div style={containerStyle}>
			<style>{animationStyles}</style>
			<div style={messageStyle}>Data Not Found</div>
		</div>
	);
};
