import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import CallsDurationChart from "./CallsDurationChart";
import { Box, Typography } from "@mui/material";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const dummyDataPoints = [
	{
		label: "Exact Word Match",
		y: Math.floor(Math.random() * 100), // Random value between 0 and 99
	},
	{
		label: "Literal Rearrangement",
		y: Math.floor(Math.random() * 100), // Random value between 0 and 99
	},
	{
		label: "Synonym Usage",
		y: Math.floor(Math.random() * 100), // Random value between 0 and 99
	},
	{
		label: "Appointment Setting",
		y: Math.floor(Math.random() * 100), // Random value between 0 and 99
	},
];

const StatiticsChart = ({ data, loading, error }) => {
	const [chartData, setChartData] = React.useState({});
	function toInt(value) {
		const intValue = parseInt(value);
		return isNaN(intValue) ? 0 : intValue;
	}

	function sumValuesByKey(list) {
		const summedValues = {};

		list.forEach((dict) => {
			Object.keys(dict).forEach((key) => {
				if (key !== "AdditionalComments") {
					const value = isNaN(dict[key]) ? dict[key] : toInt(dict[key]);

					summedValues[key] = (summedValues[key] || 0) + value;
				}
			});
		});

		return summedValues;
	}

	React.useEffect(() => {
		if (data) {
			setChartData(sumValuesByKey(data.data));
		}
		// eslint-disable-next-line
	}, [data]);

	const options = {
		title: {
			text: "Call Transcription Statistics",
			fontSize: 20,
		},
		animationEnabled: true,
		theme: "light1",
		axisY: {
			includeZero: true,
			interval: 10,
		},
		dataPointWidth: 100,
		data: [
			{
				type: "column",
				dataPoints: !data
					? dummyDataPoints
					: [
							{
								label: "Exact Word Match",
								y: chartData && chartData.WordForWordMatch,
							},
							{
								label: "Literal Rearrangement",
								y: chartData && chartData.WordForWordWithChangedOrder,
							},
							{
								label: "Synonym Usage",
								y: chartData && chartData.SynonymUsage,
							},
							{
								label: "Appointment Setting",
								y: chartData && chartData.AppointmentSetting,
							},
					  ],
			},
		],
	};

	return (
		<div style={{ margin: "auto", position: "relative" }}>
			{loading && (
				<Paper
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						backgroundColor: "rgba(0, 0, 0, 0.2)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 999,
					}}
				>
					<CircularProgress color="inherit" />
				</Paper>
			)}
			<Box
				sx={{
					display: "flex",
					px: 4,
					pt: 5,
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box flex={2}>
					<CanvasJSChart options={options} />
				</Box>

				<Box flex={1}>
					<CallsDurationChart data={data?.data[0]?.duration} />
				</Box>
			</Box>
		</div>
	);
};

export default StatiticsChart;

